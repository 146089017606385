import { Styles } from "./App";
import "../styles/BottomSection.css";
const BottomSection = () => {
  return (
    <div className="bottomContainer">
      <div id="contentContainer">
        {/* <h2 id='bottomtitle'>Step into the future of home management today. Download HomeOwner on the App Store or Google Play Store and experience a new standard of stress-free home management. </h2> */}
        <p id="bottomtext">
          Step into the future of home management today. Download HomeOwner on
          the App Store or Google Play Store and experience a new standard of
          stress-free home management.
        </p>
        <div className="app-buttons">
          <a
            href="https://play.google.com/store/apps/details?id=com.arptix.homeowner"
            className="play-store-button"
            // onClick={() => alert("App coming soon on Play store")}
          >
            <img
              src="/images/play-store-icon.jpg"
              alt="Get it on Google Play"
            />
          </a>
          <a
            href="https://apps.apple.com/ca/app/homeowner/id6448795356"
            className="app-store-button"
            // onClick={() => alert("App coming soon on Apple store")}
          >
            <img
              src="/images/apple-store-icon.jpg"
              alt="Download on the App Store"
            />
          </a>
        </div>
      </div>

      <div className="subContainer">
        <img src="/images/phone.png" alt="" />
      </div>
    </div>
  );
};

export default BottomSection;

import React from "react";
import "../styles/HeroSection.css";

const HeroContainer = ({ children }: any) => {
  const infoSectionData = [
    {
      imgSrc: "/images/img1.png",
      title: "Make Moving Manageable",
      info: "Our platform simplifies your moving process by organizing and automating various tasks. With HomeOwner, forget the stress of missing out on crucial steps in your moving journey. We help you with everything - from moving logistics to bookings or transfer services, settling dues, closing, and even new home visits.",
    },
  ];

  return (
    <div className="herocontainer">
      <div className="gradient-overlay" />
      <div className="heroitem">
        <div className="herocontent">
          <h1 className="herocontenth1">
            Welcome to HomeOwner by Arptix Solutions Inc. - Your Ultimate Home
            Management App
          </h1>
          <p id="herotext">
            At Arptix Solutions, we believe that the journey of moving and home
            management should be smooth and enjoyable. That's why we've
            developed HomeOwner, a comprehensive home management app designed to
            guide you through every step of your homeownership journey, from the
            move to maintenance.
          </p>
      
        </div>
        <div className="heroimageContainer">
          <div className="top-right-div">
            <img
              src="/images/smallimage.png"
              alt="Download on the App Store"
              className="percentageimage"
            />
          </div>
          <div className="bottom-left-div">
            <img src="/images/circle.png" alt="" />

            <p className="organizetext">Keeps you organized</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroContainer;

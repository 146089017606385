export default function AccoutDelete() {
  return (
    <div style={{ padding: 10 }}>
      <p>Account Deletion Process</p>
      <ol>
        <li>
          Navigate to the <strong>Profile</strong> tab in the application.
        </li>
        <li>
          Inside the Profile tab, select the{" "}
          <strong>Account and Security</strong> option.
        </li>
        <li>
          Within Account and Security, find and click on the{" "}
          <strong>Delete Account</strong> option.
        </li>
        <li>
          A confirmation modal will appear, asking you to confirm your decision.
        </li>
        <li>
          In the modal, select <strong>Yes</strong> to confirm account deletion
          or <strong>No</strong> to cancel.
        </li>
        <li>
          If <strong>Yes</strong> is selected, your account will be permanently
          deleted.
        </li>
      </ol>
    </div>
  );
}

import "../styles/InfoSection.css";

import React, { useEffect, useState } from "react";

const InfoSection = () => {
  const [isBelow981, setIsBelow981] = useState(window.innerWidth < 981);

  useEffect(() => {
    const handleResize = () => {
      setIsBelow981(window.innerWidth < 981);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const infoSectionData = [
    {
      imgSrc: "/images/img1.png",
      title: "Simplify Your Moving Process  ",
      info: "Our innovative home management tool eases your moving process by organizing and automating various tasks. Forget about the overwhelming checklist, with HomeOwner, you're ensured a stress-free moving experience. From coordinating moving logistics to handling utilities transfers, settling bills, and finalizing home closing, we’ve got you covered.",
    },
    {
      imgSrc: "/images/img2.png",
      title: "Swift Transition into Your New Home ",
      info: " HomeOwner takes the hassle out of moving and helps you settle into your new home swiftly. Register utilities, schedule cleaning and repair services, update addresses, and even discover essential homeowner tips all within our easy-to-use app. With HomeOwner, you have a complete moving checklist and home setup guide in your pocket.",
    },
    {
      imgSrc: "/images/img3.png",
      title: "Experience a User-Friendly Interface  ",
      info: `HomeOwner’s intuitive app dashboard allows you to visualize and track all your home management tasks in one place. With distinct sections for "Before Move" and "After Move," it's never been easier to manage your moving checklist and home maintenance tasks. Our app is designed for user-friendly navigation and task management.ScreenClip - Image of HomeOwner App Dashboard `,
    },
    {
      imgSrc: "/images/img2.png",
      title: "Stress-free Home Maintenance",
      info: "From setting reminders for HVAC filter replacements to scheduling your seasonal home maintenance, HomeOwner is the ultimate tool for hassle-free home management. Never worry about missing property tax or utility bill payments - we’ll send you reminders, so you always stay on top of your home's needs. ",
    },
    {
      imgSrc: "/images/img3.png",
      title: "Your Personal Home Management Tool",
      info: `HomeOwner lets you control your home management tasks from your phone, offering an easy and flexible solution for busy homeowners. Whether you're planning a move, setting up your new home, or handling ongoing home maintenance, HomeOwner eliminates the usual stress, making homeownership truly enjoyable.`,
    },
    {
      imgSrc: "/images/img4.png",
      title: "Empower Your Property Journey with HomeOwner",
      info: `
      The HomeOwner app streamlines the complexities of selling and buying homes into a seamless, user-friendly experience. It assists sellers with critical paperwork, listing strategies, and tailored checklists, ensuring no detail is missed. Buyers are equipped with tools for assessing affordability, calculating mortgages, and understanding every facet of home acquisition. All this, plus personalized guidance on representing oneself and working with real estate agents, is designed to make home transactions stress-free.`,
    },
  ];

  return (
    <div id="info" className="container">
      {infoSectionData.map((item, index) => (
        <div className="subsection">
          <div key={index} className="item">
            {isBelow981 ? (
              <>
                <div className="content">
                  <h3 className="informationtitle">{item.title}</h3>
                  <p id="infotext">{item.info}</p>
                </div>
                <div className="imageContainer">
                  <img src={item.imgSrc} alt={item.title} className="img" />
                </div>
              </>
            ) : (
              <>
                {index % 2 === 0 ? (
                  <>
                    <div className="imageContainer">
                      <img src={item.imgSrc} alt={item.title} className="img" />
                    </div>
                    <div className="content">
                      <h3 className="informationtitle">{item.title}</h3>
                      <p id="infotext">{item.info}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="content">
                      <h3 className="informationtitle">{item.title}</h3>
                      <p id="infotext">{item.info}</p>
                    </div>
                    <div className="imageContainer">
                      <img src={item.imgSrc} alt={item.title} className="img" />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default InfoSection;

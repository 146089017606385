export default function PrivacyPolicy() {
  return (
    <div style={{ padding: 10 }}>
      <p>Privacy Policy for HomeOwner</p>
      <p>Last updated: Nov 13, 2023 </p>
      <p>
        Arptix Solutions Inc ("us", "we", or "our") operates the HomeOwner
        mobile application (the "Service"). This page informs you of our
        policies regarding the collection, use, and disclosure of personal data
        when you use our Service and the choices you have associated with that
        data.
      </p>

      <ul>
        <li style={{ listStyle: "none" }}>
          <p>
            1. Information Collection and Use We collect several different types
            of information for various purposes to provide and improve our
            Service to you. Personal Data While using our Service, we may ask
            you to provide us with certain personally identifiable information
            that can be used to contact or identify you ("Personal Data").
            Personally identifiable information may include, but is not limited
            to:{" "}
          </p>
        </li>
        <li>
          {" "}
          <p>Full Name</p>{" "}
        </li>
        <li>
          {" "}
          <p>Current Address </p>{" "}
        </li>
        <li>
          {" "}
          <p>New Address</p>{" "}
        </li>
        <li>
          {" "}
          <p>Moving Date</p>{" "}
        </li>
        <li>
          {" "}
          <p>Email Address</p>{" "}
        </li>

        <p>
          Usage Data We may also collect information that your browser sends
          whenever you visit our Service or when you access the Service by or
          through a mobile device ("Usage Data"). Cookies and Similar
          Technologies We may use cookies and similar tracking technologies to
          track activity on our Service and hold certain information.{" "}
        </p>
        <li style={{ listStyle: "none" }}>
          <p>
            2. Self-Service Data Deletion Users have the ability to delete their
            account and all associated personal data directly through the app at
            any time. This process is irreversible and will result in the
            permanent removal of the user’s personal information from our
            databases.
          </p>
        </li>
        <li style={{ listStyle: "none" }}>
          <p>
            3. Document Uploads and Data Sensitivity For the document upload
            functionality, we advise users not to upload documents containing
            sensitive personal information. Uploaded documents are stored with
            high confidentiality and are not accessed or shared by us or any
            third parties. Users can delete these documents at their discretion.
          </p>
        </li>
        <li style={{ listStyle: "none" }}>
          <p>
            4. Security Practices and Disclaimer We are committed to ensuring
            the security and protection of the personal data that we process,
            and to providing a compliant and consistent approach to data
            protection. We have implemented various security measures including,
            but not limited to, encryption, access controls, secure software
            development practices, and regular security training for our staff.
            However, no method of transmission over the Internet, or method of
            electronic storage, is 100% secure. Therefore, while we strive to
            use commercially acceptable means to protect your Personal Data, we
            cannot guarantee its absolute security.
          </p>
        </li>
        <li style={{ listStyle: "none" }}>
          <p>
            5. Non-Disclosure of Information We do not share any personal data
            or uploaded documents with third parties. Your privacy is our top
            priority.
          </p>
        </li>
        <li style={{ listStyle: "none" }}>
          <p>
            6. Changes to This Privacy Policy We may update our Privacy Policy
            from time to time. We will notify you of any changes by posting the
            new Privacy Policy on this page.
          </p>
        </li>
        <li style={{ listStyle: "none" }}>
          <p>
            7. Contact Us If you have any questions about this Privacy Policy,
            please contact us at
            <a href="mailto:info@thehomeowner.ca.">info@thehomeowner.ca.</a>
          </p>
        </li>

        <li style={{ listStyle: "none" }}>
          By using HomeOwner, you're agreeing to be bound by this Policy. Thank
          you for choosing us as your trusted partner in home management.
        </li>
      </ul>
    </div>
  );
}

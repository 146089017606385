import { CSSProperties } from "react";
import NavBar from "./NavBar";
import HeroSection from "./HeroSection";
import InfoSection from "./InfoSection";
import BottomSection from "./BottomSection";

export type Styles = {
  [key: string]: CSSProperties;
};

function App() {
  return (
    <div>
      <NavBar />
      <HeroSection />
      <InfoSection />
      <BottomSection />
    </div>
  );
}

export default App;

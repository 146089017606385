import React from "react";
import "../styles/navbar.css";

const NavBar = () => {
  return (
    <nav>
      <div className="navbox">
        <div className="brandContainer">
          <img src="./favicon.png" alt="" className="brandImage" />
        </div>
        <div className="navLinksContainer">
          <a id="home" href="/#">
            Home
          </a>
          <a id="privacy" href="/policy">
            Privacy Policy
          </a>
          <a id="accountDelete" href="/accountDelete">
            Account Deletion
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
